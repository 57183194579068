import AIStarsIcon from "@assets/disco/icons/ai/ai-stars.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoButton, DiscoButtonProps } from "@disco-ui"
import classnames from "classnames"

type AIButtonProps = Omit<DiscoButtonProps, "color">

function AIButton(props: AIButtonProps) {
  const { children, variant = "contained", ...rest } = props
  const classes = useStyles({ variant })

  return (
    <DiscoButton
      {...rest}
      leftIcon={variant === "outlined" ? <AIStarsIcon /> : "stars"}
      className={classnames(classes.container, rest.className)}
    >
      {children}
    </DiscoButton>
  )
}

type StyleProps = {
  variant?: DiscoButtonProps["variant"]
}

const useStyles = makeUseStyles((theme) => ({
  container: ({ variant }: StyleProps) => ({
    background: theme.palette.aiGradient.bluePurple03,

    ...styleIf(variant === "outlined", {
      border: "1px solid transparent",
      borderRadius: theme.measure.borderRadius.medium,
      background: `linear-gradient(${theme.palette.background.paper} 0 0) padding-box, ${
        theme.palette.type === "dark"
          ? theme.palette.aiGradient.bluePurple02
          : theme.palette.aiGradient.bluePurple03
      } border-box`,

      "& > span, p, svg": {
        backgroundImage:
          theme.palette.type === "dark"
            ? theme.palette.aiGradient.bluePurple02
            : theme.palette.aiGradient.bluePurple03,
        backgroundClip: "text",
        textFillColor: "transparent",
      },
    }),
  }),
}))

export default AIButton
